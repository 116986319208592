import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts.css';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

window.mtcaptchaConfig = { sitekey: process.env.REACT_APP_MT_PUBLIC_KEY, renderQueue: [] };

import common_en from './translations/en/common.json'; //English language
import common_zh from './translations/zh/common.json'; // Chinese language

if (!localStorage.getItem('selectedLanguage'))
  localStorage.setItem('selectedLanguage', navigator.language.includes('zh') ? 'zh' : 'en');

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem('selectedLanguage'), //"zh", // default language to use
  resources: { en: { common: common_en }, zh: { common: common_zh } },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);
