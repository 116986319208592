import React from 'react';
import Dropzone from 'react-dropzone';
// import Footer from '../components/Footer';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import _ from 'lodash';

import AlertDialog from './../components/AlertDialog';
import ActivityLoader from './../components/ActivityLoader';
import styles from './css/FileUpload.module.css';
import imgExcelIcon from './../assets/images/xlsIcon.png';
import imgCloseIcon from './../assets/images/closeIcon.png';
import imgWarningIcon from './../assets/images/errorIcon.png';
import imgExcelUploadIcon from './../assets/images/xlsxIcon-white.png';
import imgSelectFilesIcon from './../assets/images/selectIcon.png';
import http from './../utils/http';

class Main extends React.Component {
  state = { key: 'booking', chkCaptcha1: false, chkCaptcha2: false };
  componentDidMount() {
    const mt_service = document.createElement('script');
    mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
    mt_service.async = true;
    document.body.appendChild(mt_service);

    const mt_service2 = document.createElement('script');
    mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';
    mt_service2.async = true;
    document.body.appendChild(mt_service2);

    window.mtcaptchaConfig['verified-callback'] = this.mtValidated;
    window.mtcaptchaConfig['verifyexpired-callback'] = this.mtValidationExpired;
  }
  mtValidated = (state) => {
    console.log('MTCaptcha Verified', state);
    if (state.domID === 'captchaBK') this.setState({ chkCaptcha1: true });
    else this.setState({ chkCaptcha2: true });
  };

  mtValidationExpired = (state) => {
    console.log('MTCaptcha Verification Reseted, Re-verification required');
    if (state.domID === 'captchaBK') this.setState({ chkCaptcha1: false });
    else this.setState({ chkCaptcha2: false });
  };
  render() {
    const { key, chkCaptcha1, chkCaptcha2 } = this.state;
    const { t, location, i18n } = this.props;
    return (
      <Container className={styles.container} fluid>
        <Row className={styles.tabsRow}>
          <Col className={styles.tabsCol}>
            <div className={styles.tabsContainer}>
              <Tabs unmountOnExit activeKey={key} id="tab" onSelect={(key) => this.setState({ key })}>
                <Tab tabClassName={styles.txtTabNames} eventKey="booking" title={t('fileUpload.bookingTab')}>
                  <UploadTab bookingTab={true} chkCaptcha1={chkCaptcha1} t={t} location={location} i18n={i18n} />
                </Tab>
                <Tab tabClassName={[styles.txtTabNames, 'ml-1'].join(' ')} eventKey="shipping" title={t('fileUpload.siTab')}>
                  <UploadTab bookingTab={false} chkCaptcha2={chkCaptcha2} t={t} location={location} i18n={i18n} />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        {/* <Row style={{ height: 54, width: '100vw' }}>
          <Col style={{ margin: 0, padding: 0 }}>
            <Footer as={Col} className={styles.bottomFooter} />
          </Col>
        </Row> */}
      </Container>
    );
  }
}

class UploadTab extends React.Component {
  state = {
    isBookingTab: this.props.bookingTab,
    selectedFilesBooking: [],
    selectedFilesSI: [],
    showLoader: false,
    showAlertDialog: false,
    alertDialogMsg: '',
    alertDialogButtons: null,
  };

  componentDidMount() {
    if (this.state.isBookingTab) window.mtcaptchaConfig.renderQueue.push('captchaBK');
    else window.mtcaptchaConfig.renderQueue.push('captchaSI');
  }

  removeRow = (file, noAnimation) => {
    const { isBookingTab } = this.state;
    if (isBookingTab) {
      const { selectedFilesBooking } = this.state;
      if (noAnimation) {
        selectedFilesBooking.splice(file, 1);
        this.setState({ selectedFilesBooking });
        return;
      }
      selectedFilesBooking[file].removing = true;
      this.setState({ selectedFilesBooking });
      const animate = setTimeout(() => {
        clearTimeout(animate);
        selectedFilesBooking.splice(file, 1);
        console.log(JSON.stringify(selectedFilesBooking));
        this.setState({ selectedFilesBooking });
      }, 200);
    } else {
      const { selectedFilesSI } = this.state;
      if (noAnimation) {
        selectedFilesSI.splice(file, 1);
        this.setState({ selectedFilesSI });
        return;
      }
      selectedFilesSI[file].removing = true;
      this.setState({ selectedFilesSI });

      this.setState({ selectedFilesSI });
      const animate = setTimeout(() => {
        clearTimeout(animate);
        selectedFilesSI.splice(file, 1);
        this.setState({ selectedFilesSI });
      }, 200);
    }
  };

  getBookingNo = (selectedFiles) => {
    const { isBookingTab } = this.state;
    // const selectedFiles = isBookingTab ? selectedFilesBooking : selectedFilesSI;
    let lstBookingNumber = [];
    return new Promise((resolve, reject) => {
      selectedFiles.map((file, index) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const workBook = XLSX.read(e.target.result, { type: 'buffer' });
          const workSheet = workBook.Sheets[workBook.SheetNames[0]];
          //const data = XLSX.utils.sheet_to_json(workSheet);
          if (isBookingTab) {
            //lstBookingNumber.push(data[0].__EMPTY_2);
            const bkFormBookingRefNumberCell = workSheet['D2'];
            const bkFormBookingRefNumber = bkFormBookingRefNumberCell ? bkFormBookingRefNumberCell.v : undefined;
            lstBookingNumber.push(bkFormBookingRefNumber);
          } else {
            // SI tab
            //lstBookingNumber.push(data[1][' ']);
            const siFormBkNumberCell = workSheet['M5'];
            const siFormBookingNumber = siFormBkNumberCell ? siFormBkNumberCell.v : undefined;
            lstBookingNumber.push(siFormBookingNumber);
          }
          if (selectedFiles.length - 1 === index) resolve(lstBookingNumber);
        };
        fileReader.onerror = (error) => reject(error);
      });
    });
  };

  highlightDuplicates = (lstBookings, selectedFiles) => {
    const { isBookingTab, selectedFilesBooking, selectedFilesSI } = this.state;

    for (let index = 0; index < lstBookings.length; index++)
      for (let j = 0; j < lstBookings.length; j++) {
        if (lstBookings[index] === lstBookings[j] && index !== j) {
          selectedFiles[index].invalidFile = true;
          selectedFiles[j].invalidFile = true;
        } else if (lstBookings[index] === undefined || lstBookings[index] === '') {
          selectedFiles[index].invalidFile = true;
        }
      }

    this.setState({
      selectedFilesBooking: isBookingTab ? selectedFiles : selectedFilesBooking,
      selectedFilesSI: isBookingTab ? selectedFilesSI : selectedFiles,
    });
  };

  didUpload = async () => {
    const { t } = this.props;
    const { isBookingTab, selectedFilesBooking, selectedFilesSI } = this.state;
    const selectedFiles = isBookingTab ? selectedFilesBooking : selectedFilesSI;
    let lstBooking = [];
    try {
      lstBooking = await this.getBookingNo(selectedFiles);
      const duplicates = _.uniq(lstBooking);

      if (duplicates.length !== lstBooking.length) {
        this.setState({
          alertDialogMsg: isBookingTab ? t('fileUpload.msgDuplicateRefIds') : t('fileUpload.msgDuplicateIds'),
          showAlertDialog: true,
        });
        this.highlightDuplicates(lstBooking, selectedFiles);
        return;
      }
      if (_.includes(lstBooking, undefined) || _.includes(lstBooking, '')) {
        this.setState({
          alertDialogMsg: isBookingTab ? t('fileUpload.msgNoBookingRefId') : t('fileUpload.msgNoBookingId'),
          showAlertDialog: true,
        });
        this.highlightDuplicates(lstBooking, selectedFiles);
        return;
      }
      this.checkForPortOfLoading();
    } catch (e) {
      console.log(e);
      return;
    }
    const totalCount = selectedFiles.length;
    let count = 0;
    this.setState({ showLoader: true });
    selectedFiles.forEach((file, index) => {
      const regExp = /(?:\.([^.]+))?$/;
      const timeStamp = new Date().getTime();
      const fullFName = file.name;
      const fName = fullFName.split('.').slice(0, -1).join('.');
      const filenName = isBookingTab
        ? `bk/${lstBooking[index]}-${timeStamp}-${fName}.${regExp.exec(file.path)[1]}`
        : `si/${lstBooking[index]}-${timeStamp}-${fName}.${regExp.exec(file.path)[1]}`;

      http.get(global.getUploadURL, { 'x-amz-meta-filekey': filenName }, (responseData) => {
        if (responseData === null) {
          this.setState({ showLoader: false, showAlertDialog: true, alertDialogMsg: 'Uploading Failed' });
        } else {
          http.put(responseData, file, { 'Content-Type': 'application/vnd.ms-excel' }, (result, error) => {
            if (result !== null) {
              count += 1;
              if (count === totalCount) {
                //Show message only when last file from list uploaded successfully
                this.setState({
                  showLoader: false,
                  showAlertDialog: true,
                  alertDialogMsg: `${t('fileUpload.msgSuccessUpload')}||Success`,
                  alertDialogButtons: [
                    {
                      title: t('fileUpload.btnOK'),
                      onPress: () =>
                        this.setState({
                          selectedFilesBooking: isBookingTab ? [] : selectedFilesBooking,
                          selectedFilesSI: isBookingTab ? selectedFilesSI : [],
                          alertDialogMsg: '',
                          alertDialogButtons: null,
                          showAlertDialog: false,
                        }),
                    },
                  ],
                });
              }
              this.removeRow(index, true); // remove file from list when uploaded succesfully
              global.trackAnalytics(
                'File Upload Success',
                `${filenName} File uploaded Successfully`,
                this.props.location.pathname,
                `${timeStamp}`
              );
            } else if (error) {
              this.setState({ showLoader: false, showAlertDialog: true, alertDialogMsg: error });
              global.trackAnalytics(
                'File Upload Failed',
                `${filenName} File failed to upload.`,
                this.props.location.pathname,
                `${timeStamp}`
              );
            }
          });
        }
      });
    });
  };

  getPOL = (selectedFiles) => {
    const { isBookingTab } = this.state;
    // const selectedFiles = isBookingTab ? selectedFilesBooking : selectedFilesSI;
    let lstPOL = [];
    return new Promise((resolve, reject) => {
      selectedFiles.map((file, index) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const workBook = XLSX.read(e.target.result, { type: 'buffer' });
          const workSheet = workBook.Sheets[workBook.SheetNames[0]];
          //const data = XLSX.utils.sheet_to_json(workSheet);
          //console.log(data);
          const bkFormPOLCell = workSheet['H28'];
          const bkFormPOL = bkFormPOLCell ? bkFormPOLCell.v : undefined;
          // if (isBookingTab) lstPOL.push(data[_.findIndex(data, ['__rowNum__', 27])].__EMPTY_6);
          // else lstPOL.push(data[_.findIndex(data, ['__rowNum__', 13])].__EMPTY_2);
          if (isBookingTab) lstPOL.push(bkFormPOL);
          if (selectedFiles.length - 1 === index) resolve(lstPOL);
        };
        fileReader.onerror = (error) => reject(error);
      });
    });
  };

  checkForPortOfLoading = async () => {
    let { selectedFilesBooking, selectedFilesSI, isBookingTab } = this.state;
    const { t } = this.props;
    const selectedFiles = isBookingTab ? selectedFilesBooking : selectedFilesSI;
    let lstPOL = [];
    try {
      lstPOL = await this.getPOL(selectedFiles);
      console.log('Port of Loading', lstPOL);
      if (isBookingTab && _.filter(lstPOL, (pol) => pol !== 'YANTIAN,CN').length > 0) {
        selectedFiles.map((obj, index) => {
          if (lstPOL[index] !== 'YANTIAN,CN') obj.invalidFile = true;
        });
        this.setState({
          selectedFiles,
          alertDialogMsg: t('fileUpload.msgNoYTNPortOfLoading'),
          showAlertDialog: true,
        });
        return;
      }
      // else if (!isBookingTab && _.filter(lstPOL, (pol) => pol !== 'YTN' && pol !== 'YANTIAN,CN').length > 0) {
      //   selectedFiles.map((obj, index) => {
      //     if (lstPOL[index] !== 'YTN' && lstPOL[index] !== 'YANTIAN,CN') obj.invalidFile = true;
      //   });
      //   this.setState({
      //     selectedFiles,
      //     alertDialogMsg: t('fileUpload.msgNoYTNPortOfLoading'),
      //     showAlertDialog: true,
      //   });
      //   return;
      // }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  checkForDupIds = async () => {
    let { selectedFilesBooking, selectedFilesSI, isBookingTab } = this.state;
    const { t } = this.props;
    const selectedFiles = isBookingTab ? selectedFilesBooking : selectedFilesSI;
    let lstBooking = [];
    try {
      lstBooking = await this.getBookingNo(selectedFiles);
      const lstUniqBookings = _.uniq(lstBooking);
      if (lstUniqBookings.length !== lstBooking.length) {
        this.setState({
          alertDialogMsg: isBookingTab ? t('fileUpload.msgDuplicateRefIds') : t('fileUpload.msgDuplicateIds'),
          showAlertDialog: true,
        });
        this.highlightDuplicates(lstBooking, selectedFiles);
        return;
      }
      if (_.includes(lstBooking, undefined) || _.includes(lstBooking, '')) {
        this.setState({
          alertDialogMsg: isBookingTab ? t('fileUpload.msgNoBookingRefId') : t('fileUpload.msgNoBookingId'),
          showAlertDialog: true,
        });
        this.highlightDuplicates(lstBooking, selectedFiles);
        return;
      }
      // To check length of entered ID as max 20 for BK and exact 7 for SI
      lstBooking.map((item, index) => {
        if (isBookingTab && String(item).length > 20) {
          selectedFilesBooking[index].invalidFile = true;
          this.setState({
            selectedFilesBooking,
            alertDialogMsg: t('fileUpload.msgBookingRefInvalid'),
            showAlertDialog: true,
          });
        } else if (!isBookingTab && String(item).length !== 14) {
          selectedFilesSI[index].invalidFile = true;
          this.setState({
            selectedFilesSI,
            alertDialogMsg: t('fileUpload.msgBookingNumberInvalid'),
            showAlertDialog: true,
          });
        }
      });
      //check for Port of Landing only for Bookings
      if (isBookingTab) this.checkForPortOfLoading();
    } catch (e) {
      console.log(e);
      return;
    }
  };

  onDrop = (files) => {
    let { selectedFilesBooking, selectedFilesSI, isBookingTab } = this.state;
    const { t } = this.props;

    if (isBookingTab) {
      if (selectedFilesBooking.length + files.length > 10) {
        this.setState({ showAlertDialog: true, alertDialogMsg: t('fileUpload.msgMax10') });
        return;
      }
    } else {
      if (selectedFilesSI.length + files.length > 10) {
        this.setState({ showAlertDialog: true, alertDialogMsg: t('fileUpload.msgMax10') });
        return;
      }
    }

    let duplicateFiles = [];
    files.forEach((file) => {
      const dup = _.filter(isBookingTab ? selectedFilesBooking : selectedFilesSI, ['path', file.path]);
      duplicateFiles = duplicateFiles.concat(dup);
    });
    // console.log(duplicateFiles, 'total');
    if (duplicateFiles.length === 0) {
      if (isBookingTab) this.setState({ selectedFilesBooking: files.concat(selectedFilesBooking) }, this.checkForDupIds);
      else this.setState({ selectedFilesSI: files.concat(selectedFilesSI) }, this.checkForDupIds);
    } else {
      this.setState({
        showAlertDialog: true,
        alertDialogMsg: t('fileUpload.msgOverwrite'),
        alertDialogButtons: [
          {
            title: t('fileUpload.btnYes'),
            onPress: () => {
              if (isBookingTab)
                this.setState(
                  {
                    selectedFilesBooking: files.concat(_.differenceBy(selectedFilesBooking, files, 'path')),
                    alertDialogMsg: '',
                    alertDialogButtons: null,
                    showAlertDialog: false,
                  },
                  this.checkForDupIds
                );
              else
                this.setState(
                  {
                    selectedFilesSI: files.concat(_.differenceBy(selectedFilesSI, files, 'path')),
                    alertDialogMsg: '',
                    alertDialogButtons: null,
                    showAlertDialog: false,
                  },
                  this.checkForDupIds
                );
            },
          },
          {
            title: t('fileUpload.btnNo'),
            onPress: () =>
              this.setState({
                alertDialogMsg: '',
                alertDialogButtons: null,
                showAlertDialog: false,
              }),
          },
        ],
      });
    }
  };

  disableUploadButton = () => {
    const { isBookingTab, selectedFilesBooking, selectedFilesSI } = this.state;
    const { chkCaptcha1, chkCaptcha2 } = this.props;

    if (isBookingTab === true && chkCaptcha1 && selectedFilesBooking.length > 0) {
      return false;
    } else if (isBookingTab === false && chkCaptcha2 && selectedFilesSI.length > 0) {
      return false;
    } else return true;
  };

  getSeletedFilesTitle = () => {
    const { isBookingTab, selectedFilesBooking, selectedFilesSI } = this.state;
    const { t } = this.props;
    if (isBookingTab) {
      if (selectedFilesBooking.length > 0)
        return (
          <span className={styles.txtSelectedFilesTitle}>{`${t('fileUpload.txtSelectedFiles')} (${
            selectedFilesBooking.length
          })`}</span>
        );
      else null;
    } else {
      if (selectedFilesSI.length > 0)
        return (
          <span className={styles.txtSelectedFilesTitle}>{`${t('fileUpload.txtSelectedFiles')} (${selectedFilesSI.length})`}</span>
        );
      else null;
    }
  };

  didDownloadForm = () => {
    const { isBookingTab } = this.state;
    if (isBookingTab) window.location.href = '/forms/Booking_Form_031921.xlsx';
    else window.location.href = '/forms/SI_Form_031921.xlsx';
  };

  render() {
    const { showAlertDialog, alertDialogMsg, alertDialogButtons, showLoader } = this.state;
    const { selectedFilesBooking, selectedFilesSI, isBookingTab } = this.state;
    const { t } = this.props;
    return (
      <>
        {showLoader && <ActivityLoader show={showLoader} />}
        {showAlertDialog && (
          <AlertDialog
            show={showAlertDialog}
            msg={alertDialogMsg.split('||')[0]}
            title={alertDialogMsg.split('||')[1]}
            buttons={alertDialogButtons}
            didClose={() => this.setState({ showAlertDialog: false, alertDialogMsg: '', alertDialogButtons: null })}
          />
        )}
        <div className={styles.tabWindow}>
          <div className={styles.tabWidth}>
            <section>
              <h3 className={styles.labelHeaders}>{isBookingTab ? t('fileUpload.titleBooking') : t('fileUpload.titleSI')}</h3>

              <Dropzone
                onDrop={this.onDrop}
                accept="application/vnd.ms-excel, application/msexcel, application/x-msexcel, application/x-ms-excel, application/x-excel, application/x-dos_ms_excel, application/xls, application/x-xls, application/x-msi, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              >
                {({ getRootProps, getInputProps }) => (
                  <div style={{ padding: 8, backgroundColor: '#3f96f5' }}>
                    <div className={styles.dropdownArea} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <img className={styles.imgExcelUpload} src={imgExcelUploadIcon} />
                      <p className={styles.txtUploadText}>{t('fileUpload.txtDropLine2')}</p>
                      <button className={styles.btnSelectFiles}>
                        <img className={styles.imgSelectFiles} src={imgSelectFilesIcon} />
                        {t('fileUpload.txtSelectFiles')}
                      </button>
                    </div>
                  </div>
                )}
              </Dropzone>

              <aside style={{ marginTop: 10 }}>
                {this.getSeletedFilesTitle()}
                <div className={styles.selectedFilesDiv}>
                  {isBookingTab
                    ? selectedFilesBooking.map((file, index) => (
                        <div className={file.removing ? [styles.removingFile, styles.rowDiv].join(' ') : styles.rowDiv} key={index}>
                          <div>
                            <img className={styles.imgExcel} src={file.invalidFile ? imgWarningIcon : imgExcelIcon} alt="icon" />
                            <span className={styles.txtSelectedFileName} style={file.invalidFile && { color: '#FF0000' }}>
                              {file.path}
                            </span>
                          </div>
                          <img className={styles.imgClose} src={imgCloseIcon} alt="close" onClick={() => this.removeRow(index)} />
                        </div>
                      ))
                    : selectedFilesSI.map((file, index) => (
                        <div className={file.removing ? [styles.removingFile, styles.rowDiv].join(' ') : styles.rowDiv} key={index}>
                          <div>
                            <img className={styles.imgExcel} src={file.invalidFile ? imgWarningIcon : imgExcelIcon} alt="icon" />
                            <span className={styles.txtSelectedFileName} style={file.invalidFile && { color: '#FF0000' }}>
                              {file.path}
                            </span>
                          </div>
                          <img className={styles.imgClose} src={imgCloseIcon} alt="close" onClick={() => this.removeRow(index)} />
                        </div>
                      ))}
                </div>
              </aside>
              <div className={styles.btnBar}>
                <div className={styles.captcha} style={{ width: '50%' }}>
                  {isBookingTab ? <div id="captchaBK" /> : <div id="captchaSI" />}
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  <button className={styles.btnDownload} onClick={this.didDownloadForm}>
                    {t('fileUpload.txtDownload1')}
                    <br />
                    <span className={styles.btnSubTitle}>{t('fileUpload.txtDownload2')}</span>
                  </button>

                  <button className={styles.btnUpload} disabled={this.disableUploadButton()} onClick={this.didUpload}>
                    {t('fileUpload.txtUpload1')}
                    <br />
                    <span className={styles.btnSubTitle}>{t('fileUpload.txtUpload2')}</span>
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

Main.propTypes = { t: PropTypes.func, location: PropTypes.object, i18n: PropTypes.object };
UploadTab.propTypes = {
  t: PropTypes.func,
  bookingTab: PropTypes.bool,
  location: PropTypes.object,
  pathname: PropTypes.string,
  i18n: PropTypes.object,
  chkCaptcha1: PropTypes.bool,
  chkCaptcha2: PropTypes.bool,
};

export default withTranslation('common')(withRouter(Main));
