import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './css/Footer.module.css';

const Footer = (props) => (
  <footer className={[styles.footer, 'page-footer', 'font-small', 'blue', props.className && props.className].join(' ')}>
    <div className={[styles.smallText, 'footer-copyright', 'text-center', 'py-3'].join(' ')}>
      {`© 2021 Matson Inc, All rights reserved. | `}
      <a className={styles.footerText} href="https://www.matson.com">
        Matson Home
      </a>
      <a className={styles.footerText} href="https://www.matson.com">
        {` | Terms & Conditions `}
        {` | 京ICP证19039236号 `}
      </a>
    </div>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(Footer);
