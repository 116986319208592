import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as Fathom from 'fathom-client';
import styles from './App.module.css';

import FileUpload from './pages/FileUpload';
import Header from './components/Header';
import Footer from './components/Footer';

global.hCAPTCHAKey = 'a20760b3-e2f2-4a20-aac8-3eae7779eb4b';
// global.ReCAPTCHAKey = '6LcK1ywaAAAAACaR35QG9VbMunmfmuSwtZ2MJ_d2';
global.getUploadURL = process.env.REACT_APP_FORM_API_URL;

Fathom.load(process.env.REACT_APP_FATHOM_SITE_ID);

global.trackAnalytics = (category, action, pageurl, user) => {
  // console.log(`Analytics category: ${category} action: ${action} pageurl: ${pageurl} user: ${user}`);
  window.ga('send', { hitType: 'event', eventCategory: category, eventAction: action, eventLabel: user });
  window.ga('send', 'pageview', pageurl);
};

function App() {
  return (
    <div className={styles.container}>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={FileUpload} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
