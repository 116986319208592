import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import imgTickIcon from './../assets/images/TickIcon.png';
import imgErrorIcon from './../assets/images/errorIcon.png';
import styles from './css/AlertDialog.module.css';

const getTitle = (props) => {
  if (props.title === 'Success') return props.t('alertDialog.success');
  else if (props.title === 'Confirm') return props.t('alertDialog.confirm');
  else return props.t('alertDialog.error');
};

const AlertDialog = (props) => (
  <Modal id="alertModal" show={props.show} centered className={styles.modalContainer}>
    <div className={styles.alertCard}>
      {/* <div className={styles.btnCloseDiv}>
        <img src={imgCloseIcon} className={styles.closeIcon} onClick={props.didClose} />
      </div> */}
      <div className={styles.imgIconDiv}>
        <img className={styles.imgIcon} src={props.title === 'Success' ? imgTickIcon : imgErrorIcon} alt="icon" />
      </div>
      <div className={styles.txtTitleDiv}>
        <span className={styles.txtTitle}>{getTitle(props)}</span>
      </div>
      <div className={styles.txtMsgDiv}>
        <span className={styles.txtMsg}>{props.msg}</span>
      </div>
      <div className={styles.btnButtonDiv}>
        {props.buttons ? (
          props.buttons.map((obj, index) => (
            <Button key={index} block className={styles.btnButton} onClick={obj.onPress}>
              {obj.title}
            </Button>
          ))
        ) : (
          <Button block className={styles.btnButton} onClick={props.didClose}>
            {props.t('alertDialog.btnOK')}
          </Button>
        )}
      </div>
    </div>
  </Modal>
);

AlertDialog.propTypes = {
  show: PropTypes.bool,
  didClose: PropTypes.func,
  title: PropTypes.string,
  buttons: PropTypes.array,
  msg: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation('common')(AlertDialog);
