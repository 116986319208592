import axios from 'axios';
// import moment from 'moment';

let http = {
  get: (url, headers, callback) => {
    console.log('GET Request: ' + url);
    axios
      .get(url, { headers })
      .then((responseData) => {
        console.log('GET Response: ' + JSON.stringify(responseData));

        callback && callback(responseData.data, null);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        callback && callback(null, 'ERROR');
      });
  },
  put: (url, data, headers, callback) => {
    console.log('PUT Request: ' + url);
    axios
      .put(url, data, headers)
      .then((responseData) => {
        console.log('PUT Response: ' + JSON.stringify(responseData));
        // if (responseData) {
        callback && callback(responseData.data, null);
        // }
      })
      .catch((error) => {
        callback && callback(null, 'Uploading failed');
        console.log(error);
        console.log(error.response);
      });
  },
};

export default http;
