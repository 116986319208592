import React from 'react';
import { Modal } from 'react-bootstrap';
import { Digital } from 'react-activity';
import PropTypes from 'prop-types';

import 'react-activity/dist/react-activity.css';
import styles from './css/ActivityLoader.module.css';

const ActivityLoader = (props) => (
  <Modal id="activityModal" show={props.show} centered className={styles.modalContainer}>
    <Digital size={50} color="#ffffff" />
  </Modal>
);

ActivityLoader.propTypes = {
  show: PropTypes.bool,
};

export default ActivityLoader;
