import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ActivityLoader from './../components/ActivityLoader';
// import http from './../utils/http';
import styles from './css/Header.module.css';
import imgLogo from './../assets/images/logo.png';
import dropdownIcon from './../assets/images/op1.png';

class Header extends Component {
  state = {
    showLoader: false,
    showAlertDialog: false,
    alertDialogMsg: '',
    alertDialogButtons: null,
  };

  componentDidMount() {}

  toggleLoader = (showLoader) => this.setState({ showLoader });

  changeLanguage = (lang) => {
    if(this.props.i18n.language === lang) return;
    localStorage.setItem('selectedLanguage', lang);
    this.props.i18n.changeLanguage(lang);
    window.location.reload();
  };

  render() {
    const { showLoader } = this.state;
    return (
      <>
        {showLoader && <ActivityLoader show={showLoader} />}
        <Navbar collapseOnSelect expand="lg" fixed="top" className={[styles.headerBar, 'justify-content-between'].join(' ')}>
          <Navbar.Brand>
            <Nav.Link style={{ paddingLeft: 0 }} to="/" href="/" as={Link} eventKey="1">
              <img className={styles.logo} height="35" width="153" src={imgLogo} alt="logo" />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Collapse className={'justify-content-end'} style={{ backgroundColor: '#fff', margin: '20px 0px' }}>
            <NavDropdown
              alignRight
              title={
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  Change Language
                  <img src={dropdownIcon} style={{ height: 20, width: 20, marginLeft: 5 }} alt="arrow" />
                </span>
              }
              id="header-nav-dropdown"
              className={styles.collasibleNavDropdown}
            >
              <NavDropdown.Item eventKey="5" onClick={() => this.changeLanguage('en')}>
                {`🇺🇸 English`}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="6" onClick={() => this.changeLanguage('zh')}>
                {`🇨🇳 Mandarin`}
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

Header.propTypes = {
  i18n: PropTypes.object,
  changeLanguage: PropTypes.func,
};

export default withTranslation('common')(withRouter(Header));
